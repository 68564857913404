// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// todo: prod sso string: sso: `https://sso.empire.edu/adfs/ls/idpinitiatedsignon.aspx?loginToRp=https://sms-leads.empire-dev.com/sms/assert`

export const environment = {
  production: true,
  server: `https://chattalent.empire-dev.com/chatrelay`,
  websocket: `wss://chattalent.empire-dev.com/chatrelay`,
  sso: `https://chattalent.empire-dev.com/chatrelay/ssotest`,
  crmapi: `https://leads.empire.edu/`
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
